
import { Vue, Component, Prop, Emit, PropSync } from 'vue-property-decorator'
import { columns, statusMapping } from '@/modules/background_check/bgc-static'
import { Global } from '@/store'
import BackgroundChecksTableTop from './BackgroundChecksTableTop.vue'
import { BackgroundCheck } from '@/modules/models'
import { DateTime } from 'luxon'


@Component({
	components: {
		BackgroundChecksTableTop
	}
})
export default class BackgroundChecksTable extends Vue {
	@Prop() data!: any[]
	@Prop() markPassedDisabled!: boolean
	@PropSync('checkedRows', { default: []}) syncedCheckedRows!: BackgroundCheck[]

	@Emit() rowClicked(){}

	@Emit() selectPending(national_ids: string[]){}
	@Emit() markPassed(){}
	@Emit() sendPassed(){}
	@Emit() search(term: string){}

	columns = columns
	donwloadLinkAttrs = {}

	get statusMapping(){
		return status => statusMapping[status]
	}

	copy(text) {
		if(text){
			navigator.clipboard.writeText(text)
		} else {
			navigator.clipboard.writeText(this.data.map(d => d.person.national_id).join('\n'))
		}

		this.$buefy.toast.open({
			message: text ? 'Cedula copiada!' : 'Cedulas copiadas!',
			type: 'is-success',
			duration: 500,
		})
	}

	async download(){
		const text = this.data.map(d =>
			`="${d.person.national_id}",${d.person.display_name},${d.formattedCreatedAt.replace(', ','/')}`
		).join('\n')
		const blob = new Blob([text], {type: "text/csv"})

		this.donwloadLinkAttrs = {
			href: URL.createObjectURL(blob),
			download: `batch_${DateTime.local().toFormat("d_LLL_hh'h'-mm'm'-ss's'_a")}.csv`
		}

		await new Promise(resolve => setTimeout(resolve))
		;(this.$refs.download as HTMLLinkElement).click()
	}

	onFileLoaded(e: any){
		const csv = this.$refs.csv as HTMLInputElement
		const file = csv.files![0]
		if(file) {
			const reader = new FileReader()
			reader.readAsText(file)
			reader.onload = (e: any) => {
				const text = e.target.result
				const cleanLine = l => l.split(',').shift().replace(/[^\d]/g, '')
				const national_ids = text.split('\n').map(cleanLine)
				this.selectPending(national_ids)
			}
		}
	}
}
