
import { Vue, Component } from 'vue-property-decorator'
import { Person, BackgroundCheck } from '@/modules/models'
import BackgroundCheckForm from './BackgroundCheckForm.vue'
import BackgroundChecksTable from './BackgroundChecksTable.vue'
import { BackgroundChecks as BackgroundChecksStore } from '@/store'
import _values from 'lodash/values'


@Component({
	components: {
		BackgroundCheckForm,
		BackgroundChecksTable,
	}
})
export default class BackgroundChecks extends Vue {
	showPersonModal = false
	activeTab = 0
	activeBackgroundCheck = null
	checkedRows: BackgroundCheck[] = []
	searchTerm = ''

	get backgroundChecks(): BackgroundCheck[]{
		return _values(BackgroundChecksStore.backgroundChecks)
	}


	get markPassedDisabled(){
		return this.checkedRows.length &&
			this.checkedRows.some(cr => cr.status !== 'pending')
	}

	get filteredBackgroundChecks(){
		return this.backgroundChecks.filter(b =>
			b.person.national_id.includes(this.searchTerm) ||
			b.person.display_name.toLowerCase().includes(this.searchTerm.toLowerCase())
		)
	}

	async mounted(){
		const loading = this.$buefy.loading.open({})
		await BackgroundChecksStore.loadBackgroundChecks()
		loading.close()
	}

	rowClicked(row: any){
		this.activeBackgroundCheck = row.clone()
		this.showPersonModal = true
	}

	async updateBackgroundCheck(backgroundCheck, photos){
		BackgroundChecksStore.updateBackgroundCheck(backgroundCheck)
		const loading = this.$buefy.loading.open({})
		try {
			await BackgroundChecksStore.sendBackgroundCheck({ id: backgroundCheck.id, photos })
		} finally {
			loading.close()
		}

		this.showPersonModal = false
	}

	selectPending(national_ids: string[]){
		this.checkedRows = this.filteredBackgroundChecks.filter(b => b.status == 'pending' && national_ids.includes(b.person.national_id))
	}

	markPassed(){
		BackgroundChecksStore.approveBackgroundChecks(this.checkedRows)
		this.checkedRows = []
	}

	async sendPassed(){
		const loading = this.$buefy.loading.open({})
		try {
			await BackgroundChecksStore.sendApprovedBackgroundChecks()
		} finally {
			loading.close()
		}
	}
}
