
import { Vue, Component } from 'vue-property-decorator'
import Sidebar from '@/components/Sidebar.vue'
import Navbar from '@/components/Navbar.vue'
import Content from '@/components/Content.vue'
import Loading from '@/components/Loading.vue'
import { Global } from '../store'

@Component({
  components: {
    Sidebar,
    Navbar,
    Content,
    Loading
  }
})
export default class Main extends Vue{
  get isAuthenticated(){
    return Global.isAuthenticated
  }
}
