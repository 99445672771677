
import { Vue, Component } from 'vue-property-decorator'
import { Global } from '@/store'

@Component
export default class Navbar extends Vue{
  get user(){
    return Global.user
  }

  logout(){
    localStorage.clear()
    this.$router.push({ name: 'Login' })
  }
}
