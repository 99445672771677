import _debounce from 'lodash/debounce'


export function Debounce(ms: number){
	return (target: object, key: string, descriptor: PropertyDescriptor) => {
		const original = descriptor.value
		descriptor.value = _debounce(original, ms)
		return descriptor
	}
}
