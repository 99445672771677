

import { Vue, Component, Prop, Emit } from 'vue-property-decorator'
import { Debounce } from "@/modules/common"
import { BackgroundChecks as BackgroundChecksStore } from '@/store'
import _values from 'lodash/values'


@Component
export default class BackgroundChecksTableTop extends Vue {
	@Prop() markPassedDisabled!: boolean

	@Emit() loadFile(){}
	@Emit() markPassed(){}
	@Emit() sendPassed(){}
	@Emit() download(){}

	@Debounce(200)
	@Emit()
	search(term: string){}

	get sendPassedDisabled(){
		const values = _values(BackgroundChecksStore.backgroundChecks)
		return values.length && !values.some(b => b.status == 'passed')
	}
}

