
import { Vue, Component, Prop } from 'vue-property-decorator'
import { statusMapping } from '@/modules/background_check/bgc-static'
import { Global } from '@/store'

@Component
export default class StatusRadio extends Vue{
	@Prop() value!: string

	statusMapping = statusMapping

	onInput(value: string){
		this.$emit('input', value)
	}

}
