
import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator'
import _startCase from 'lodash/startCase'
import _isEqual from 'lodash/isEqual'
import { tipoSentencia, estadoActual, razones } from '@/modules/background_check/bgc-static'
import { BackgroundCheck, Case } from '@/modules/models'
import StatusRadio from '@/components/StatusRadio.vue'
import { DateTime } from 'luxon'
import axios from 'axios'
import { ARGUMENT_DETAILS, DEPORTED_DETAILS } from '@/modules/background_check/bgc-static'

import moment from 'moment'
import 'moment/locale/es'
moment.locale('es')


@Component({
	components: {
		StatusRadio
	}
})
export default class BackgroundCheckForm extends Vue {
	@Prop({ default: {}}) backgroundCheck!: BackgroundCheck

	@Emit()
	update(backgroundCheck: BackgroundCheck, photos: File[]){}

	DateTime = DateTime
	tipoSentencia = tipoSentencia
	estadoActual = estadoActual
	razones = razones
	open = {}
	personPhotoUrl: ArrayBuffer | string = ''
	photos: File[] = []
	photosForDisplay: (ArrayBuffer | string)[] = []
	deported = false
	index = 0

	monthNames = moment.months().map(x => _startCase(x))
	dayNames = moment.weekdaysShort().map(x => _startCase(x.slice(0,-1)))

	get hasCases(){
		return Boolean(this.backgroundCheck.details.JUD_PENAL.cases.length)
	}

	get sendDisabled(){
		return (
			this.backgroundCheck.status === 'pending' || (
				this.backgroundCheck.status === 'failed' &&
				!this.backgroundCheck.details.JUD_PENAL.cases.length
			)
		)
	}

	@Watch('photos')
	async onPhotosChange(){
		this.photosForDisplay = await Promise.all(this.photos.map(p => this.readFile(p)))
	}

	async onDeportedChange(ficha, value){
		if(value){
			ficha.details = DEPORTED_DETAILS
			this.index++ // Only way to get HTML5 validation to turn off
		} else {
			ficha.details = null
		}
	}

	save(){
		const form = this.$refs.form as HTMLFormElement
		const isValid = form.checkValidity()
		if(!isValid){
			this.$buefy.notification.open({
				duration: 2000,
				message: 'Existen campos invalidos',
				type: 'is-danger',
				hasIcon: true,
			})
			const elements = Array.from(form.elements) as HTMLInputElement[]
			elements.forEach(e => (e.focus(), e.blur()))
			// @ts-ignore: Call buefy datepicker's method
			this.$refs.sentencia.forEach(e => e.checkHtml5Validity())
			return
		}

		this.update(this.backgroundCheck, this.photos)
	}

	onPhotosInput(photos){
		if([...this.photos, ...photos].length > 3) {
			this.$buefy.notification.open({
				duration: 2000,
				message: 'Se han tomado las primeras 3 fotos',
				type: 'is-warning',
				hasIcon: true,
			})
		}

		this.photos = [...this.photos, ...photos].slice(0,3)
	}

	addNewCase(){
		this.backgroundCheck.details.JUD_PENAL.addNewCase()
	}

	uncollapse(index, content){
		if(index in this.open){
			this.open = {}
			return
		}

		this.open = {}
		this.open[index] = content.clientHeight + 'px'
	}

	removeCase(index){
		this.backgroundCheck.details.JUD_PENAL.cases.splice(index, 1)
		this.open = {}
	}

	handlePaste(event: ClipboardEvent) {
		const items = event.clipboardData!.items
		let photos: File[] = []

		for (const item of items) {
			if (item.type.indexOf('image') === 0) {
				photos.push(item.getAsFile()!)
			}
		}

		if(photos.length)
			this.onPhotosInput(photos)
	}

	removePhoto(index){
		this.photos.splice(index, 1)
	}

	async downloadPersonPhoto(){
		const { data } = await axios.get(`/api/v1/persons/do/${this.backgroundCheck.person.id}/photo`, {
			responseType: 'blob'
		})

		this.personPhotoUrl = await this.readFile(data)
	}

	async readFile(data){
		return new Promise<ArrayBuffer | string>(resolve => {
			const reader = new FileReader()
			reader.onload = () => resolve(reader.result!)
			reader.readAsDataURL(data)
		})
	}

	created(){
		this.downloadPersonPhoto()
	}
}
